import React, { useState, useEffect } from 'react';
import Sidebar from '../../modules/main/Sidebar';
import Header from '../../modules/main/Header';
import Footer from '../../modules/main/Footer';
import { Dropdown, Row, Col, Card, Button, Table, ListGroup, Form } from 'react-bootstrap';
import APIService from "../../api/APIService";
import { useParams, Link } from "react-router-dom";
import moment from 'moment';
import { display_date_format, databaseRoleCode } from '../../settings';
import { filterDropdownOptionByName, check, replaceSpecialCharacters } from "../../utils/functions.js";
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { DELETE_PROJECT_MEMBER } from '../../modules/lang/Project';
import { connect } from "react-redux";
import SimpleBar from 'simplebar-react';
import SearchIcon from "../../assets/img/icons/serach.svg";
import AvatarImg from "../../assets/img/placeholder-image.png";
import linkifyHtml from 'linkify-html';
import NotFound from "../auth/NotFound";

function ProjectDetail({ name, userData }) {
    let { id } = useParams();
    const [projectDetail, setProjectDetail] = useState([]);
    const [projectMembers, setProjectMembers] = useState([]);
    const [staffList, setStaffList] = useState([]);
    const [staffListForFilter, setStaffListForFilter] = useState([]);
    const [process, setProcess] = useState(true);
    const [assignToSearch, setAssignToSearch] = useState('');
    const [checkAccess, setCheckAccess] = useState(true);

    useEffect(() => {
        APIService.getProjectForEdit(id)
            .then((response) => {
                if (response.data?.status) {
                    if (response.data?.data.name === undefined) {
                        setCheckAccess(false);
                    }
                    setProjectDetail(response.data?.data);
                    if (response.data?.data?.assign_members) {
                        setProjectMembers(response.data?.data?.assign_members);
                    }
                    setProcess(false);
                }
            });

        APIService.getAllMembers('')
            .then((response) => {
                if (response.data?.status) {
                    setStaffList(response.data?.data);
                    setStaffListForFilter(response.data?.data);
                }
                else {
                    setStaffList([]);
                    setStaffListForFilter([]);
                }
            });
    }, [id]);

    const handleProjectMemberDelete = (assignId, name) => {
        confirmAlert({
            title: 'Confirm',
            message: DELETE_PROJECT_MEMBER.replace("{user_name}", name),
            buttons: [
                {
                    label: 'Yes',
                    className: 'btn btn-primary btn-lg',
                    onClick: () => {
                        let params = {};
                        params["staffid"] = assignId;
                        params["projectid"] = id;
                        APIService.deleteProjectMember(params)
                            .then((response) => {
                                if (response.data?.status) {
                                    let newProjectMemberList = projectMembers.filter(function (arr) {
                                        return arr.id !== assignId;
                                    })
                                    setProjectMembers(newProjectMemberList);
                                    toast.success(response.data?.message, {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }
                                else {
                                    toast.error(response.data?.message, {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }
                            });
                    }
                },
                {
                    label: 'No',
                    className: 'btn btn-outline-secondary btn-lg',
                    onClick: () => {

                    }
                }
            ]
        });
    }

    const handleAssignToSearch = (value) => {
        setAssignToSearch(value);
        filterDropdownOptionByName(staffList, value, setStaffListForFilter);
    }

    const handleAddRemoveMembers = (e) => {
        handleAssignToSearch('');
        let assignId = parseInt(e.target.value);
        if (e.target.checked) {
            let params = {};
            params["staffid"] = e.target.value;
            params["projectid"] = id;
            APIService.addProjectMember(params)
                .then((response) => {
                    if (response.data?.status) {
                        setProjectMembers(response.data?.data?.assign_members);
                        toast.success(response.data?.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                    else {
                        toast.error(response.data?.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                });
        }
        else {
            handleProjectMemberDelete(assignId, e.target.name);
        }
    };

    return (
        <>
            {checkAccess ?
                <>
                    <Sidebar />
                    <div className="main-content">
                        <Header pagename={name} />
                        <div className="inner-content pt-0 px-0">
                            <div className="project-detail-page">
                                {!process &&
                                    <>
                                        <div className="bg-white py-3 px-4 px-lg-7  taskboard-header page-inner-header">
                                            <div className="g-5 align-items-center row">
                                                <div className="col-12 col-md-auto col col-lg-6">
                                                    <h3 className='float-start me-2'>{projectDetail?.name}</h3>
                                                    {check(['projects.update'], userData?.permission) &&
                                                        <Link to={`/edit-project/${projectDetail?.id}`} type="button" className="btn-icon circle-btn btn btn-soft-primary btn-sm">
                                                            <i className="icon-edit"></i>
                                                        </Link>
                                                    }
                                                </div>
                                                <div className="col-12 col-md-auto col col-lg-6 text-end">
                                                    {check(['tasks.view'], userData?.permission) &&
                                                        <>
                                                            {userData.role_code === databaseRoleCode.clientCode ?
                                                                <Link to={`/tasks?pid=${projectDetail?.id}&pname=${projectDetail?.name}`} type="button" className="float-right me-5">
                                                                    Show All Tasks
                                                                </Link>
                                                                :
                                                                <>
                                                                    <Link to={`/tasks?pid=${projectDetail?.id}&pname=${projectDetail?.name}`} type="button" className="float-right me-5">
                                                                        Dev Plan Tasks
                                                                    </Link>
                                                                    <Link to={`/site-addons-tasks?pid=${projectDetail?.id}&pname=${projectDetail?.name}`} type="button" className="float-right">
                                                                        Site Add-on Tasks
                                                                    </Link>
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='pt-9 px-4 px-lg-7'>
                                            <Row>
                                                <Col xs={12} xl={7} xxl={7}>
                                                    <Card className="rounded-10 border border-gray-100 mb-4">
                                                        <Card.Body className="p-0">
                                                            <div className="d-flex align-items-center px-3 px-md-4 py-3 border-bottom border-gray-100">
                                                                <h3 className="card-header-title mb-0 my-md-2 ps-md-3">Overview</h3>
                                                            </div>
                                                        </Card.Body>
                                                        <Card.Body className="px-md-4 py-4">
                                                            <div className="px-md-3 py-md-3">
                                                                <Table hover size="md" className="list-table border-top-0">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="font-weight-semibold">Project #</td>
                                                                            <td>{projectDetail?.id}</td>
                                                                        </tr>
                                                                        {projectDetail?.agency_id !== 0 &&
                                                                            <tr>
                                                                                <td className="font-weight-semibold">Agency</td>
                                                                                <td>
                                                                                    {userData.role_code !== databaseRoleCode.clientCode && userData.role_code !== databaseRoleCode.agencyCode && userData.role_code !== databaseRoleCode.agencyMemberCode ?
                                                                                        <Link to={`/agency-user-detail/${projectDetail?.agency_id}`}>{projectDetail?.agency_name}</Link>
                                                                                        :
                                                                                        projectDetail?.agency_name
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        }
                                                                        <tr>
                                                                            <td className="font-weight-semibold">Customer</td>
                                                                            <td>
                                                                                {check(['customers.view'], userData?.permission) ?
                                                                                    <Link to={`/customer/profile/${projectDetail?.clientid}`}>{projectDetail?.clientname}</Link>
                                                                                    :
                                                                                    projectDetail?.clientname
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="font-weight-semibold">Status</td>
                                                                            <td>{projectDetail?.status_name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="font-weight-semibold">Date Created</td>
                                                                            <td>{projectDetail.project_created && moment(projectDetail.project_created).format(display_date_format)}</td>
                                                                        </tr>
                                                                        {projectDetail.start_date && moment(projectDetail.start_date).format(display_date_format) !== "11-30-1899" &&
                                                                            <tr>
                                                                                <td className="font-weight-semibold">Start Date</td>
                                                                                <td>{projectDetail.start_date && moment(projectDetail.start_date).format(display_date_format)}</td>
                                                                            </tr>
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                    {projectDetail.description && userData.role_code !== databaseRoleCode.agencyCode && userData.role_code !== databaseRoleCode.agencyMemberCode && userData.role_code !== databaseRoleCode.clientCode &&
                                                        <Card className="rounded-10 border border-gray-100 mb-4">
                                                            <Card.Body className="p-0">
                                                                <div className="d-flex align-items-center px-3 px-md-4 py-3 border-bottom border-gray-100">
                                                                    <h3 className="card-header-title mb-0 my-md-2 ps-md-3">Description</h3>
                                                                </div>
                                                            </Card.Body>
                                                            <Card.Body className="px-md-4 py-4">
                                                                <div className="px-md-3 py-md-3">
                                                                    <div dangerouslySetInnerHTML={{ __html: replaceSpecialCharacters(linkifyHtml(projectDetail.description !== undefined && projectDetail.description !== null && projectDetail.description !== "undefined" ? projectDetail.description : '<p class="text-muted">No description for this project</p>')).replaceAll("<a ", "<a rel='nofollow' target='_blank' ") }}></div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    }
                                                </Col>
                                                {userData.role_code !== databaseRoleCode.clientCode &&
                                                    <Col xs={12} xl={5} xxl={5}>
                                                        <Card className="rounded-10 border border-gray-100 mb-4">
                                                            <Card.Body className="p-0">
                                                                <div className="d-flex align-items-center px-3 px-md-4 py-3 border-bottom border-gray-100">
                                                                    <h3 className="card-header-title mb-0 my-md-2 ps-md-3">Members</h3>
                                                                    <Dropdown className="project-drop-down category-dropdown ms-auto pe-md-3" autoClose="outside">
                                                                        {check(['projects.update'], userData?.permission) &&
                                                                            <Dropdown.Toggle as="a" bsPrefix="d-toggle" className="btn btn-dark-100 btn-icon btn-sm rounded-circle btn-sm shadow-none" id="dropdown-basic">
                                                                                <i className="icon-add"></i>
                                                                            </Dropdown.Toggle>
                                                                        }
                                                                        <Dropdown.Menu as="ul" align="down" className="dropdown-menu-end p-2 w-100">
                                                                            <Dropdown.Header className="d-flex align-items-center pt-4 pb-3 pb-0 px-4">
                                                                                <div className="search-box w-100">
                                                                                    <div className="input-group bg-white border border-gray-100 rounded-5 align-items-center w-100">
                                                                                        <img src={SearchIcon} alt="Search" />
                                                                                        <input type="search" className="form-control border-0" placeholder="Name" value={assignToSearch} onChange={(e) => handleAssignToSearch(e.target.value)} />
                                                                                    </div>
                                                                                </div>
                                                                            </Dropdown.Header>
                                                                            <SimpleBar className="dropdown-body">
                                                                                {staffListForFilter.map((drp, index_staff) => (
                                                                                    <Dropdown.Item as="li" key={index_staff}>
                                                                                        <Form.Check className="m-0 form-check-sm" type="checkbox" name={drp.name} label={drp.name} id={`categorycheckbox${drp.id}`} checked={projectMembers.filter(function (arr) { return arr.id === drp.id; }).length > 0} value={drp.id} onChange={handleAddRemoveMembers} />
                                                                                    </Dropdown.Item>
                                                                                ))}
                                                                            </SimpleBar>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            </Card.Body>
                                                            <Card.Body className="px-md-4 py-4">
                                                                <div className="px-md-3">
                                                                    <ListGroup className="list-group-flush">
                                                                        {projectMembers.length > 0 && projectMembers.map((assignUser, index) => (
                                                                            <ListGroup.Item key={index}>
                                                                                <div className="row align-items-center px-md-2">
                                                                                    <div className="col-auto">
                                                                                        <span className="avatar avatar-circle avatar-border">
                                                                                            {userData.role_code !== databaseRoleCode.clientCode && userData.role_code !== databaseRoleCode.agencyCode && userData.role_code !== databaseRoleCode.agencyMemberCode ?
                                                                                                <Link to={`${assignUser.is_not_staff === 1 ? '/agency-user-detail/' : '/user-detail/'}${assignUser.id}`}>
                                                                                                    {assignUser.profile_image !== '' && assignUser.profile_image !== null ?
                                                                                                        <img className="avatar-img" src={`${assignUser.profile_image}`} alt={assignUser.name} onError={({ currentTarget }) => {
                                                                                                            currentTarget.onerror = null;
                                                                                                            currentTarget.src = AvatarImg;
                                                                                                        }} />
                                                                                                        :
                                                                                                        <img className="avatar-img" src={AvatarImg} alt={assignUser.name} />
                                                                                                    }
                                                                                                </Link>
                                                                                                :
                                                                                                <>
                                                                                                    {assignUser.profile_image !== '' && assignUser.profile_image !== null ?
                                                                                                        <img className="avatar-img" src={`${assignUser.profile_image}`} alt={assignUser.name} onError={({ currentTarget }) => {
                                                                                                            currentTarget.onerror = null;
                                                                                                            currentTarget.src = AvatarImg;
                                                                                                        }} />
                                                                                                        :
                                                                                                        <img className="avatar-img" src={AvatarImg} alt={assignUser.name} />
                                                                                                    }
                                                                                                </>
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col p-0">
                                                                                        <h6 className="mb-1 font-weight-semibold">
                                                                                            {userData.role_code !== databaseRoleCode.clientCode && userData.role_code !== databaseRoleCode.agencyCode && userData.role_code !== databaseRoleCode.agencyMemberCode ?
                                                                                                <Link to={`${assignUser.is_not_staff === 1 ? '/agency-user-detail/' : '/user-detail/'}${assignUser.id}`}>{assignUser.name}</Link>
                                                                                                :
                                                                                                assignUser.name
                                                                                            }
                                                                                        </h6>
                                                                                        {assignUser.designation &&
                                                                                            <p className="card-text small text-gray-600 lh-sm">{assignUser.designation}</p>
                                                                                        }
                                                                                    </div>
                                                                                    {check(['projects.update'], userData?.permission) &&
                                                                                        <div className="col-auto">
                                                                                            <Button variant="soft-danger" className="btn-icon circle-btn" onClick={() => { handleProjectMemberDelete(assignUser.id, assignUser.name) }}><i className="icon-delete"></i></Button>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </ListGroup.Item>
                                                                        ))}
                                                                    </ListGroup>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                }
                                            </Row>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <Footer />
                    </div>
                </>
                :
                <NotFound />
            }
        </>
    );
}

const mapStateToProps = (state) => ({
    userData: state.Auth.user
})

export default connect(mapStateToProps)(ProjectDetail)