import React, { useState, useEffect, useRef } from 'react';
import Sidebar from '../../modules/main/Sidebar';
import Header from '../../modules/main/Header';
import Footer from '../../modules/main/Footer';
import { Form, Button, Spinner, Dropdown, OverlayTrigger, Tooltip, Col, Row, Accordion, Card } from 'react-bootstrap';
import APIService from "../../api/APIService";
import { validateForm } from "../../utils/validator.js"
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import { ProjectValidator } from "../../modules/validation/ProjectValidator";
import Select from 'react-select';
import AvatarImg from "../../assets/img/placeholder-image.png";
import SimpleBar from 'simplebar-react';
import { filterDropdownOptionByName } from "../../utils/functions.js";
import SearchIcon from "../../assets/img/icons/serach.svg";
import { connect } from "react-redux";
import AdddashedIcon from "../../assets/img/icons/add-dashed.svg";
import { databaseRoleCode, tinymceInit } from '../../settings';
import { Editor } from "@tinymce/tinymce-react";
import { format } from 'date-fns';
import SingleDatePickerControl from '../../modules/custom/SingleDatePicker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const REACT_APP_TINYMCE_APIKEY = process.env.REACT_APP_TINYMCE_APIKEY;

function AddProject({ userData, name }) {
    let history = useHistory();
    let nameInput = useRef();
    const [process, setProcess] = useState(false);
    const [formErrors, setFormErrors] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [client, setClient] = useState(0);
    const [selectedAssignedBy, setSelectedAssignedBy] = useState([]);
    const [staffListForFilter, setStaffListForFilter] = useState([]);
    const [staffList, setStaffList] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const defaultPermission = [{ "view_tasks": 0, "create_tasks": 0, "edit_tasks": 0, "comment_on_tasks": 0, "view_task_comments": 0, "hide_tasks_on_main_tasks_table": 0, "view_task_attachments": 0, "view_task_checklist_items": 0, "upload_on_tasks": 0 }];
    const [projectPermission, setProjectPermission] = useState(defaultPermission);
    const [assignToSearch, setAssignToSearch] = useState('');
    const [dueDate, setDueDate] = useState(null);
    const [date, setDate] = useState(null);

    //for html editor
    const [htmlContent, setHtmlContent] = useState();

    const onEditorChange = (e) => {
        setHtmlContent(e);
    }

    const onEditorClick = (e) => {
        document.getElementById("projectBody").click();
    }

    const customStyles = {
        option: (styles, state) => ({
            ...styles,
            cursor: 'pointer',
        }),
        control: (styles) => ({
            ...styles,
            cursor: 'pointer',

        }),
    };

    useEffect(() => {
        APIService.getAllClients()
            .then((response) => {
                if (response.data?.status) {
                    let Clients = response.data?.data;
                    const newClientList = Clients.map(({ id, name }) => ({ label: name, value: id }));
                    setClientList([{ label: 'Select', value: 0 }, ...newClientList]);
                }
            });

        APIService.getAllMembers('')
            .then((response) => {
                if (response.data?.status) {
                    setStaffList(response.data?.data);
                    setStaffListForFilter(response.data?.data);
                }
            });
    }, []);

    const handleClientSelect = (e) => {
        setClient(e.value);
    }

    const addProject = async () => {
        setProcess(true);
        setFormErrors([]);

        let validate = validateForm((ProjectValidator(nameInput.current?.value, 'not required')));
        if (Object.keys(validate).length) {
            setFormErrors(validate);
            setProcess(false);
        }
        else {
            const params = new FormData();
            params.append("name", nameInput.current?.value);
            params.append("clientid", client);
            params.append("settings", JSON.stringify(projectPermission[0]));
            let assigned_members_list = selectedAssignedBy.map((obj) => obj.id);
            if (!assigned_members_list.includes(userData?.id)) {
                assigned_members_list.push(userData?.id);
            }
            params.append("assigned_members", assigned_members_list.join());
            params.append("description", htmlContent ? htmlContent : '');
            params.append("addedfrom", userData?.id);
            if (date) {
                params.append("start_date", format(date, "yyyy-MM-dd"));
            }
            if (dueDate) {
                params.append("due_date", format(dueDate, "yyyy-MM-dd"));
            }

            APIService.addProject(params)
                .then((response) => {
                    if (response.data?.status) {
                        toast.success(response.data?.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setTimeout(() => {
                            history.push('/projects');
                        }, 1500)
                        setProcess(false);
                    }
                    else {
                        toast.error(response.data?.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setProcess(false);
                    }
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setProcess(false);
                });
        }
    };

    const onAssignBySelect = (e) => {
        handleAssignToSearch('');
        let id = parseInt(e);
        if (id > 0) {
            let addRemovechk = selectedAssignedBy.filter(function (arr) { return arr.id === id; }).length > 0;
            if (!addRemovechk) {
                let newstaffList = staffList.filter(function (arr) {
                    return arr.id === id;
                })
                setSelectedAssignedBy(selectedAssignedBy.concat(newstaffList));
            }
            else {
                let newstaffList = selectedAssignedBy.filter(function (arr) {
                    return arr.id !== id;
                })
                setSelectedAssignedBy(newstaffList);
            }
        }
    };

    const handleAssignToSearch = (value) => {
        setAssignToSearch(value);
        filterDropdownOptionByName(staffList, value, setStaffListForFilter);
    }

    const handleProjectPermissionChange = (e) => {
        let element = e.target.value;
        if (e.target.checked) {
            let newProjectPermission = projectPermission;
            newProjectPermission[0][element] = 1;
            setProjectPermission(newProjectPermission);
            setRefresh(!refresh);
        } else {
            if (e.target.value === "view_tasks") {
                setProjectPermission(defaultPermission);
            }
            else {
                let newProjectPermission = projectPermission;
                newProjectPermission[0][element] = 0;
                setProjectPermission(newProjectPermission);
            }
            setRefresh(!refresh);
        }
    };

    useEffect(() => {
    }, [refresh]);

    const checkSettingChecked = (setting) => {
        return projectPermission.some((data) => (data.view_tasks === 1 || data.view_tasks === "1") && (data[setting] === 1 || data[setting] === "1"));
    }

    return (
        <>
            <Sidebar />
            <div className="main-content">
                <Header pagename={name ? name : ''} />
                <div className="inner-content">
                    <Card className="rounded-10 p-6">
                        <Card.Body className="p-0" id="projectBody">
                            <Form onSubmit={async e => { e.preventDefault(); await addProject() }}>
                                <Row className="g-4">
                                    <Col sm={12} md={4} lg={4} xl={3}>
                                        <Form.Group className="mb-7 w-100 validation-required" controlId="roleName">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Project Name" ref={nameInput} className={`form-control ${formErrors.nameInput && 'is-invalid'}`} />
                                            {formErrors.nameInput && (
                                                <span className="text-danger">{formErrors.nameInput}</span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4} lg={4} xl={3}>
                                        <Form.Group className="mb-7 w-100" controlId="roleCode">
                                            <Form.Label>Customer</Form.Label>
                                            <Select styles={customStyles} className='custom-select' options={clientList} onChange={handleClientSelect}
                                                value={clientList.filter(function (option) {
                                                    return option.value === client;
                                                })} />
                                            {formErrors.clientInput && (
                                                <span className="text-danger">{formErrors.clientInput}</span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    {userData.role_code !== databaseRoleCode.clientCode && userData.role_code !== databaseRoleCode.agencyMemberCode &&
                                        <Col sm={12} md={6} lg={6} xl={6}>
                                            <div className="task-label-left mb-3">
                                                <span className="font-14 font-medium dark-1 font-weight-medium">Assigned To</span>
                                            </div>
                                            <div className="task-label-right position-relative mb-7">
                                                <div className="avatar-group">
                                                    {selectedAssignedBy.map((assignUser, index) => (
                                                        <span className="avatar avatar-md avatar-circle" key={index}>
                                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${index}`}> {assignUser.name}</Tooltip>}>
                                                                {assignUser.profile_image !== '' && assignUser.profile_image !== null ?
                                                                    <img className="avatar-img" src={`${assignUser.profile_image}`} alt={assignUser.name} onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null;
                                                                        currentTarget.src = AvatarImg;
                                                                    }} />
                                                                    :
                                                                    <img className="avatar-img" src={AvatarImg} alt={assignUser.name} />
                                                                }
                                                            </OverlayTrigger>
                                                        </span>
                                                    ))}
                                                    <span className="avatar avatar-md avatar-circle">
                                                        <Dropdown className="project-drop-down category-dropdown " onSelect={onAssignBySelect} autoClose="outside">
                                                            <Dropdown.Toggle as="a" bsPrefix="no-toggle" className="dark-2 font-weight-medium font-12 cursor-pointer" id="assign"><img className="avatar-img" src={AdddashedIcon} alt="Add Member" /></Dropdown.Toggle>
                                                            <Dropdown.Menu as="ul" align="down" className="p-2 w-100">
                                                                <Dropdown.Header className="d-flex align-items-center pt-4 pb-3 pb-0 px-4">
                                                                    <div className="search-box w-100">
                                                                        <div className="input-group bg-white border border-gray-100 rounded-5 align-items-center w-100">
                                                                            <img src={SearchIcon} alt="Search" />
                                                                            <input type="search" className="form-control border-0" placeholder="Name" value={assignToSearch} onChange={(e) => handleAssignToSearch(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Header>
                                                                <SimpleBar className="dropdown-body">
                                                                    {staffListForFilter.map((drp, index) => (
                                                                        <Dropdown.Item as="li" key={index} eventKey={drp.id} className={`${selectedAssignedBy.filter(function (arr) {
                                                                            return arr.id === drp.id;
                                                                        }).length > 0 ? 'active' : ''}`}>
                                                                            <div className="d-flex d-flex align-items-center cursor-pointer w-100">
                                                                                {drp.profile_image !== '' && drp.profile_image !== null ?
                                                                                    <img className="avatar avatar-xs avatar-circle me-1" src={`${drp.profile_image}`} alt={drp.name} onError={({ currentTarget }) => {
                                                                                        currentTarget.onerror = null;
                                                                                        currentTarget.src = AvatarImg;
                                                                                    }} />
                                                                                    :
                                                                                    <img className="avatar avatar-xs avatar-circle me-1" src={AvatarImg} alt={drp.name} />
                                                                                }
                                                                                <div className="ps-3">
                                                                                    <div className="font-weight-regular dark-1 font-14 d-block">{drp.name}</div>
                                                                                </div>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                    ))}
                                                                </SimpleBar>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </span>
                                                </div>
                                            </div>
                                            {formErrors.selectedAssignedBy && (
                                                <span className="text-danger">{formErrors.selectedAssignedBy}</span>
                                            )}
                                        </Col>
                                    }
                                </Row>
                                <Row className="g-4">
                                    <Col sm={12} md={4} lg={4} xl={3}>
                                        <Form.Group className="mb-7 w-100" controlId="roleName">
                                            <Form.Label>Start Date</Form.Label>
                                            <SingleDatePickerControl
                                                selected={date}
                                                onDateChange={(date) => setDate(date)}
                                                onChange={(date) => setDate(date)}
                                                minDate={(userData.role_code !== databaseRoleCode.clientCode && userData.role_code !== databaseRoleCode.agencyCode && userData.role_code !== databaseRoleCode.agencyMemberCode) ? null : new Date() }
                                                maxDate={dueDate}
                                                isClearable
                                                className={`form-control ${formErrors.date && 'is-invalid'}`}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4} lg={4} xl={3}>
                                        <Form.Group className="mb-7 w-100" controlId="roleCode">
                                            <Form.Label>Due Date</Form.Label>
                                            <SingleDatePickerControl
                                                selected={dueDate}
                                                onDateChange={(date) => setDueDate(date)}
                                                onChange={(date) => setDueDate(date)}
                                                minDate={ (userData.role_code !== databaseRoleCode.clientCode && userData.role_code !== databaseRoleCode.agencyCode && userData.role_code !== databaseRoleCode.agencyMemberCode) ? null : date ? date : new Date() }
                                                isClearable
                                                className={`form-control ${formErrors.dueDate && 'is-invalid'}`}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {userData.role_code !== databaseRoleCode.agencyCode && userData.role_code !== databaseRoleCode.agencyMemberCode && userData.role_code !== databaseRoleCode.clientCode &&
                                    <Row className="g-4">
                                        <Col sm={12} md={12}>
                                            <Form.Label>Project Description</Form.Label>
                                            {/* <Editor
                                                apiKey={REACT_APP_TINYMCE_APIKEY}
                                                value={htmlContent}
                                                init={tinymceInit}
                                                onEditorChange={onEditorChange}
                                                onClick={onEditorClick}
                                            /> */}
                                            <ReactQuill theme="snow" value={htmlContent} onChange={setHtmlContent} />
                                        </Col>
                                    </Row>
                                }
                                <Row className="g-4">
                                    <Col sm={12} md={12}>
                                        <Accordion alwaysOpen defaultActiveKey={[0]} className="dashboard-accordion">
                                            <Accordion.Item eventKey={0} className="">
                                                <Accordion.Header as="h4" className="pt-6">Project Settings</Accordion.Header >
                                                <Accordion.Body className="pb-9 px-0">
                                                    <Row className="g-4">
                                                        <Col sm={12} md={6} lg={4} xl={4}>
                                                            <Form.Check type="checkbox" id="allow-customer-task-view" label="Allow customer to view tasks" value="view_tasks" checked={checkSettingChecked("view_tasks")} onChange={handleProjectPermissionChange} />
                                                        </Col>
                                                        <Col sm={12} md={6} lg={4} xl={4}>
                                                            <Form.Check type="checkbox" id="allow-customer-task-create" label="Allow customer to create tasks" value="create_tasks" checked={checkSettingChecked("create_tasks")} onChange={handleProjectPermissionChange} disabled={!checkSettingChecked("view_tasks")} />
                                                        </Col>
                                                        <Col sm={12} md={6} lg={4} xl={4}>
                                                            <Form.Check type="checkbox" id="allow-customer-task-edit" label="Allow customer to edit tasks (only tasks created from contact)" value="edit_tasks" checked={checkSettingChecked("edit_tasks")} onChange={handleProjectPermissionChange} disabled={!checkSettingChecked("view_tasks")} />
                                                        </Col>
                                                        <Col sm={12} md={6} lg={4} xl={4}>
                                                            <Form.Check type="checkbox" id="allow-customer-task-comment" label="Allow customer to comment on project tasks" value="comment_on_tasks" checked={checkSettingChecked("comment_on_tasks")} onChange={handleProjectPermissionChange} disabled={!checkSettingChecked("view_tasks")} />
                                                        </Col>
                                                        <Col sm={12} md={6} lg={4} xl={4}>
                                                            <Form.Check type="checkbox" id="allow-customer-task-view-comment" label="Allow customer to view task comments" value="view_task_comments" checked={checkSettingChecked("view_task_comments")} onChange={handleProjectPermissionChange} disabled={!checkSettingChecked("view_tasks")} />
                                                        </Col>
                                                        <Col sm={12} md={6} lg={4} xl={4}>
                                                            <Form.Check type="checkbox" id="allow-customer-task-view-comment-uwp" label="Allow customer to view task comments from UnlimitedWP" value="hide_tasks_on_main_tasks_table" checked={checkSettingChecked("hide_tasks_on_main_tasks_table")} onChange={handleProjectPermissionChange} disabled={!checkSettingChecked("view_tasks")} />
                                                        </Col>
                                                        <Col sm={12} md={6} lg={4} xl={4}>
                                                            <Form.Check type="checkbox" id="allow-customer-task-view-attachment" label="Allow customer to view task attachments" value="view_task_attachments" checked={checkSettingChecked("view_task_attachments")} onChange={handleProjectPermissionChange} disabled={!checkSettingChecked("view_tasks")} />
                                                        </Col>
                                                        <Col sm={12} md={6} lg={4} xl={4}>
                                                            <Form.Check type="checkbox" id="allow-customer-task-view-checklist" label="Allow customer to view task checklist items" value="view_task_checklist_items" checked={checkSettingChecked("view_task_checklist_items")} onChange={handleProjectPermissionChange} disabled={!checkSettingChecked("view_tasks")} />
                                                        </Col>
                                                        <Col sm={12} md={6} lg={4} xl={4}>
                                                            <Form.Check type="checkbox" id="allow-customer-task-upload-attachment" label="Allow customer to upload attachments on tasks" value="upload_on_tasks" checked={checkSettingChecked("upload_on_tasks")} onChange={handleProjectPermissionChange} disabled={!checkSettingChecked("view_tasks")} />
                                                        </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                </Row>
                                <div className="mt-5">
                                    <Button disabled={process} className="me-2" variant="soft-secondary" size="md" type="button" onClick={() => { history.push('/projects'); }}>Cancel</Button>
                                    <Button disabled={process} variant="primary" size="md" type="submit">
                                        {
                                            !process && 'Save'
                                        }
                                        {
                                            process && <><Spinner size="sm" animation="border" className="me-1" />Save</>
                                        }
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
                <Footer />
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    userData: state.Auth.user
})

export default connect(mapStateToProps)(AddProject)