import React from 'react';
import { ToastContainer } from 'react-toastify';

export default function Toast() {
  return (
    <>
     <ToastContainer /> 
    </>
  );
}
