import React, { useState, useEffect } from 'react';
import { Accordion, Dropdown, Tooltip, OverlayTrigger, Badge } from 'react-bootstrap';
import moment from 'moment';
import AvatarImg from "../../assets/img/placeholder-image.png";
import DataTable from "../../modules/custom/DataTable/DataTable";
import { pagination, display_date_format, databaseRoleCode, popperConfig } from '../../settings';
import { check } from "../../utils/functions.js";
import APIService from '../../api/APIService';
//import PermissionCheck from "../../modules/Auth/PermissionCheck";
import ReactImageFallback from "react-image-fallback";
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { COPY_LINK_MSG } from '../../modules/lang/Task';
import { toast } from 'react-toastify';

export default function TaskListAccordion({ index, taskStatusDataList, heading, id, cstShowViewTaskModal, cstShowEditTaskModal, project, agency, assigned_id, myFollowingTasks, staffId, recurringTask, projectList, agencyList, deleteTask, updateTaskStatus, taskStatusList, userData, taskStroke, task_type, total_task, customerId }) {

    const [page, setPage] = useState(0);
    const [searchFilter, setSearchFilter] = useState('');
    const [process, setProcess] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [sort, setSort] = useState(pagination.sorting);
    const [sortby, setSortBy] = useState('id');
    const [perPageSize, setPerPageSize] = useState(pagination.perPageRecord);
    const [taskList, setTaskList] = useState(taskStatusDataList);
    const [firstLoad, setFirstLoad] = useState(true);

    const handleLoadMore = async (e) => {
        setPage(page + 1);
        setPerPageSize(perPageSize + pagination.perPageRecord);
        setProcess(true);
    };

    const copyTaskLink = (taskId, task_type) => {
        if (task_type === 1) {
            navigator.clipboard.writeText(`${window.location.origin}/view-site-addons-task/${taskId}`);
        }
        else {
            navigator.clipboard.writeText(`${window.location.origin}/view-task/${taskId}`);
        }
        toast.success(COPY_LINK_MSG, { position: toast.POSITION.TOP_RIGHT });
    };

    let columns = [
        {
            Header: '#',
            id: 'id',
            disableSortBy: false,
            accessor: (taskList) => taskList.name,
            Cell: ({ row }) => (
                <>
                    <div onClick={() => { cstShowViewTaskModal(row?.original?.id) }} className="cursor-pointer">
                        {row?.original?.id}
                    </div>
                </>
            ),
        },
        {
            Header: 'Name',
            id: 'name',
            accessor: (taskList) => taskList.name,
            Cell: ({ row }) => (
                <>
                    <div onClick={() => { cstShowViewTaskModal(row?.original?.id) }} className={(taskStroke.filter(function (arr) { return arr.id === row?.original?.id && arr.status !== id; }).length > 0) ? 'line-through cursor-pointer d-flex flex-column align-items-start' : 'cursor-pointer d-flex flex-column align-items-start'}>
                        <div className="list-table-task-name">{row?.original?.name}</div>
                        {/* <div className="font-12 my-1">{row?.original?.project_name}</div> */}
                        <Link to={`/project-detail/${row?.original?.project_id}`} className="font-12 my-1">{row?.original?.project_name}</Link>
                        {row?.original?.recurring === 1 &&
                            <Badge bg="info" className="font-weight-semibold font-12 p-2 mt-1">Recurring Task</Badge>
                        }
                    </div>
                    <div className="row-action mt-1">
                        
                        <span className='text-primary cursor-pointer' onClick={() => { copyTaskLink(row?.original?.id, row?.original?.task_type) }}>
                            Copy Link
                        </span>
                        
                        <span className="font-12 px-1">|</span>
                        <Link to={row?.original?.task_type === 1 ? `/view-site-addons-task/${row?.original?.id}` : `/view-task/${row?.original?.id}`} target="_blank" className='text-primary cursor-pointer'>
                            View
                        </Link>
                        {check(['tasks.update'], userData?.permission) || check(['tasks.delete'], userData?.permission) ?
                            <span className="font-12 px-1">|</span> : ''
                        }
                        {check(['tasks.update'], userData?.permission) &&
                            <>
                                {userData?.role_code !== databaseRoleCode.clientCode || row?.original?.settings?.edit_tasks === 1 ?
                                    <span className='text-primary cursor-pointer' onClick={() => { cstShowEditTaskModal(row?.original?.id) }}>
                                        Edit
                                    </span> : ''
                                }
                            </>
                        }
                        {check(['tasks.update'], userData?.permission) && check(['tasks.delete'], userData?.permission) &&
                            <span className="font-12 px-1">|</span>
                        }
                        {check(['tasks.delete'], userData?.permission) &&
                            <>
                                {userData?.role_code !== databaseRoleCode.clientCode || (userData?.role_code === databaseRoleCode.clientCode && row?.original?.added_by === userData?.id && row?.original?.is_added_from_contact === 1) ?
                                    <span className="text-danger cursor-pointer" onClick={() => { deleteTask(row?.original?.id) }}>
                                        Delete
                                    </span>
                                : ''}
                            </>
                        }
                    </div>
                </>
            ),
        },
        {
            Header: 'Start Date',
            id: 'startdate',
            accessor: (taskList) => taskList.startdate && moment(taskList.startdate).format(display_date_format),
        },
        {
            Header: 'Due Date',
            id: 'duedate',
            accessor: (taskList) => taskList.duedate && moment(taskList.duedate).format(display_date_format),
        },
        {
            Header: 'Priority Order',
            id: 'priority',
            accessor: (taskList) => taskList.priority,
        },
        {
            Header: 'Status',
            id: 'status',
            accessor: (taskList) => taskList.task_status_name,
            Cell: ({ row }) => (
                <>
                    {check(['tasks.update'], userData?.permission) ?
                        <Dropdown>
                            <Dropdown.Toggle size='sm' variant={row?.original?.backgroundColor} id={`dropdown-variants-status-${row?.original?.id}`}>
                                {row?.original?.task_status_name}
                            </Dropdown.Toggle>

                            <Dropdown.Menu popperConfig={popperConfig}>
                                {taskStatusList.filter(function (arr) { return arr.label !== row?.original?.task_status_name; }).map((status, index) => (
                                    <Dropdown.Item key={index} onClick={() => { updateTaskStatus(row?.original?.id, status.id) }}>
                                        {`Mark as ${status.label}`}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                        :
                        <Badge className="font-weight-semibold font-12 p-2" bg={row?.original?.backgroundColor}>{row?.original?.task_status_name}</Badge>
                    }
                </>
            ),
        },
    ];

    if (userData?.role_code !== databaseRoleCode.clientCode) {
        columns = [
            ...columns,
            {
                Header: 'Assigned to',
                id: 'assigned_to',
                disableSortBy: true,
                accessor: (taskList) => taskList.assign_member,
                Cell: ({ row }) => (
                    <>
                        <div className="avatar-group">
                            {row?.original?.assign_member?.map((user, index) => {
                                return index < 5 &&
                                    <span className="avatar avatar-sm avatar-circle" key={index}>
                                        {userData.role_code !== databaseRoleCode.clientCode && userData.role_code !== databaseRoleCode.agencyCode && userData.role_code !== databaseRoleCode.agencyMemberCode ?
                                            <Link to={`/user-detail/${user.id}`}>
                                                <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${user.name}`}> {user.name}</Tooltip>}>
                                                    {user.profile_image !== '' && user.profile_image !== null ?
                                                        <ReactImageFallback
                                                            src={`${user.profile_image}`}
                                                            fallbackImage={AvatarImg}
                                                            initialImage={AvatarImg}
                                                            alt={user.name}
                                                            className="avatar-img" />
                                                        :
                                                        <img className="avatar-img" src={AvatarImg} alt={user.name} />
                                                    }
                                                </OverlayTrigger>
                                            </Link>
                                            :
                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${user.name}`}> {user.name}</Tooltip>}>
                                                {user.profile_image !== '' && user.profile_image !== null ?
                                                    <ReactImageFallback
                                                        src={`${user.profile_image}`}
                                                        fallbackImage={AvatarImg}
                                                        initialImage={AvatarImg}
                                                        alt={user.name}
                                                        className="avatar-img" />
                                                    :
                                                    <img className="avatar-img" src={AvatarImg} alt={user.name} />
                                                }
                                            </OverlayTrigger>
                                        }
                                    </span>
                            })}
                            {row?.original?.assign_member?.length > 5 &&
                                <span className="avatar avatar-sm avatar-circle">
                                    <Dropdown className="assigned-drop-down category-dropdown h-100" autoClose="outside">
                                        <Dropdown.Toggle as="a" bsPrefix="no-toggle" className="dark-2 font-weight-medium font-12 cursor-pointer" id="assign"><div className="avatar-initials avatar-dark-light border-transprant">+{row?.original?.assign_member.length - 5}</div></Dropdown.Toggle>
                                        <Dropdown.Menu as="ul" align="down" className="p-2" popperConfig={popperConfig}>
                                            <SimpleBar className="dropdown-body">
                                                {row?.original?.assign_member?.map((drp, index) => (
                                                    <Dropdown.Item as="li" key={index} eventKey={drp.id}>
                                                        {userData.role_code !== databaseRoleCode.clientCode && userData.role_code !== databaseRoleCode.agencyCode && userData.role_code !== databaseRoleCode.agencyMemberCode ?
                                                            <div className="d-flex d-flex align-items-center cursor-pointer w-100">
                                                                <Link to={`/user-detail/${drp.id}`}>
                                                                    {drp.profile_image !== '' && drp.profile_image !== null ?
                                                                        <img className="avatar avatar-md avatar-circle me-1" src={`${drp.profile_image}`} alt={drp.name} onError={({ currentTarget }) => {
                                                                            currentTarget.onerror = null;
                                                                            currentTarget.src = AvatarImg;
                                                                        }} />
                                                                        :
                                                                        <img className="avatar avatar-md avatar-circle me-1" src={AvatarImg} alt={drp.name} />
                                                                    }
                                                                </Link>
                                                                <div className="ps-3">
                                                                    <Link to={`/user-detail/${drp.id}`}>
                                                                        <div className="font-weight-regular dark-1 font-14 d-block">{drp.name}</div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="d-flex d-flex align-items-center cursor-pointer w-100">
                                                                {drp.profile_image !== '' && drp.profile_image !== null ?
                                                                    <img className="avatar avatar-md avatar-circle me-1" src={`${drp.profile_image}`} alt={drp.name} onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null;
                                                                        currentTarget.src = AvatarImg;
                                                                    }} />
                                                                    :
                                                                    <img className="avatar avatar-md avatar-circle me-1" src={AvatarImg} alt={drp.name} />
                                                                }
                                                                <div className="ps-3">
                                                                    <div className="font-weight-regular dark-1 font-14 d-block">{drp.name}</div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </Dropdown.Item>
                                                ))}
                                            </SimpleBar>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </span>
                            }
                        </div>
                    </>
                ),
            }
        ];
    }

    /*columns = [
        ...columns,
        {
            Header: 'Action',
            disableSortBy: true,
            Cell: ({ row }) => (
                <>
                    <Dropdown className="category-dropdown edit-task-dropdown">
                        <Dropdown.Toggle as="div" bsPrefix="no-toggle" className="cursor-pointer" id="edit-task"><i className="fa-solid fa-ellipsis-vertical"></i></Dropdown.Toggle>
                        <Dropdown.Menu as="ul" align="down" className="dropdown-menu-end p-2" popperConfig={popperConfig}>
                            {check(['tasks.update'], userData?.permission) &&
                                <>
                                    {userData?.role_code !== databaseRoleCode.clientCode || row?.original?.settings?.edit_tasks === 1 ?
                                        <>
                                            {taskStatusList.filter(function (arr) { return arr.label !== heading; }).map((status, index) => (
                                                <Dropdown.Item key={index} onClick={() => { updateTaskStatus(row?.original?.id, status.id) }}>
                                                    {`Mark as ${status.label}`}
                                                </Dropdown.Item>
                                            ))}

                                            <Dropdown.Item onClick={() => { cstShowEditTaskModal(row?.original?.id) }}>
                                                Edit Task
                                            </Dropdown.Item>
                                        </> : ''
                                    }
                                </>
                            }
                            {check(['tasks.view'], userData?.permission) &&
                                <>
                                    {userData?.role_code !== databaseRoleCode.clientCode || row?.original?.settings?.view_tasks === 1 ?
                                        <Dropdown.Item onClick={() => { cstShowViewTaskModal(row?.original?.id) }}>
                                            View Task
                                        </Dropdown.Item> : ''
                                    }
                                </>
                            }
                            {check(['tasks.delete'], userData?.permission) &&
                                <>
                                    <Dropdown.Item className="text-danger" onClick={() => { deleteTask(row?.original?.id) }}>
                                        Delete Task
                                    </Dropdown.Item>
                                </>
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            ),
        }
    ];*/

    const getData = async (e) => {
        let search_by_status = id;
        let task_type_new = userData?.role_code === databaseRoleCode.clientCode ? '0,1' : task_type;
        let params = "?assigned_to_me=" + assigned_id + "&search_by_status=" + search_by_status + "&task_type=" + task_type_new + "&my_following_tasks=" + myFollowingTasks;
        params = params + "&sort=" + sort + "&limit=" + pagination.perPageRecord + "&page=" + page + "&sort_by=" + sortby;
        if (recurringTask) {
            params = params + "&recurring=1";
        }
        if (project !== '') {
            params = params + "&search_by_project=" + project.id;
        }
        if (agency !== '') {
            params = params + "&search_by_agency=" + agency.id;
        }
        if (staffId.length > 0) {
            let staff_id_list = staffId.map((obj) => obj.id);
            params = params + "&staffid=" + staff_id_list.join(",");
        }
        params = params + `&customer_id=${customerId ? customerId : 0}`;

        APIService.getTaskList(params)
            .then((response) => {
                if (response.data?.status) {
                    setShowButton(response.data?.pagination?.total_pages > page);
                    let newData = [];
                    if (page === 1) {
                        newData = response.data?.data;
                    }
                    else {
                        newData = taskList.concat(response.data?.data);
                    }
                    setProcess(false);
                    setTaskList(newData);
                }
            });
    }

    useEffect(() => {
        setTaskList(taskStatusDataList);
        setShowButton(taskStatusDataList.length > 4);
    }, [taskStatusDataList]);

    useEffect(() => {
        setFirstLoad(false);
        if (!firstLoad) {
            getData();
        }
    }, [sort, sortby, searchFilter, page]);

    return (
        <Accordion.Item eventKey={index}>
            <Accordion.Header as="h4">
                {heading}
                {/* <Badge pill className='ms-2 status-badge' bg="info">{total_task}</Badge> */}
            </Accordion.Header>
            <Accordion.Body className="pt-0">
                <div className={`card rounded-10 p-6 all-dev-task all-dev-task-without-checkbox`}>
                    {/* When sorting or load more then taskList state will update and below table will show data */}
                    {taskList &&
                        <DataTable columns={columns} data={taskList} searchFilter={searchFilter} setSearchFilter={setSearchFilter} pageNumber={1} setPageNumber={setPage} perPageSize={perPageSize} setPerPageSize={setPerPageSize} loading={false} isPagination={false} isLengthChange={false} showButton={showButton} handleLoadMore={handleLoadMore} btnProcess={process} setSort={setSort} setSortingBy={setSortBy} />
                    }
                </div>
            </Accordion.Body>
        </Accordion.Item>
    );
}
